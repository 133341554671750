<template>
<b-navbar :toggleable="isMobile" variant="faded" type="light" sticky>

    <b-navbar-brand to="/">
        <img src="../assets/logo.jpeg" alt="">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse">
        <template>
            <b-icon-three-dots animation="cylon"></b-icon-three-dots>
        </template>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" :is-nav="true">
        <b-navbar-nav>
            <b-nav-item to="/" :active="routeName === 'home'">Home</b-nav-item>
            <b-nav-item to="/about" :active="routeName === 'about'">About</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
            <b-nav-item right>
                <b-button variant="outline-info" class="mb-2 button-wa" @click="openWhatsapp">
                    <b-icon-whatsapp icon="power" aria-hidden="true"></b-icon-whatsapp> Whatsapp
                </b-button>
            </b-nav-item>
            <b-nav-item right>
                <b-button variant="outline-info" class="mb-2 button-in" @click="openInstagram" target="_blank">
                    <b-icon-instagram icon="power" aria-hidden="true"></b-icon-instagram> Intagram
                </b-button>
            </b-nav-item>
        </b-navbar-nav>
    </b-collapse>

</b-navbar>
</template>

<script>
import { BIconThreeDots, BIconWhatsapp, BIconInstagram } from 'bootstrap-vue';

export default {
    name: 'NavBar',
    components: {
        BIconThreeDots,
        BIconWhatsapp,
        BIconInstagram,
    },
    computed: {
        routeName() {
        return this.$route.name;
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        openWhatsapp() {
            window.open('https://api.whatsapp.com/send?phone=918197827187&text=Hi', '_blank').focus();
        },
        openInstagram() {
            if (this.isMobile) {
                window.open('instagram://user?username=nthyashambavi', '_blank').focus();
            }
            window.open('https://www.instagram.com/nthyashambavi', '_blank').focus();
        }
    }
}
</script>

<style lang="scss">
</style>>
