<template>
  <div id="app">
    <nav-bar></nav-bar>
    <router-view/>
  </div>
</template>

<script>
import NavBar from './components/NavBar';

export default {
  name: 'App',
  components: {
    NavBar,
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  nav {
    padding: 30px;
    background: white;
    a {
      font-size: 20px;
      font-weight: bold;
      color: #2c3e50;

      &.active {
        color: #42b983;
      }
    }
    .navbar-toggler {
      border: none;
      &.collapsed {
        color: #42b983
      }
    }
    .navbar-brand {
      img {
        height: 52px;
          width: 115px;
      }
    }
    .nav-item {
      padding: 0 20px;
      .nav-link {
          .button-wa {
              color: #25D366;
              border-color: #25D366;
              &:hover {
                color: white;
                background-color: #25D366;
                box-shadow: 0px 3px 10px rgba(0,0,0,.25);
                border-radius: 0px;
              }
          }
          .button-in {
              color: #d6249f;
              border-color: #d6249f;
              &:hover {
                color: white;
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                border-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%) 1;
                box-shadow: 0px 3px 10px rgba(0,0,0,.25);
              }
          }
        }
    }
  }
}

</style>
