<template>
  <div class="home">
    <div class="temp-wrapper">
      <img :class="[ isMobile ? 'responsive' : '' ]" alt="Maintenace" src="../assets/underMaintenance.jpg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
  computed: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
      },
  },
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  padding-bottom: 20px;
  .temp-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    img {
      height: 850px;
      &.responsive {
        width: 100%;
        height: 60%;
      }
    }
  }
}
</style>
